import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Employment from '../views/Employment.vue'
import Track from '../views/Track.vue'
import LoadTender from "../views/LoadTender.vue"
import NotFound from "../views/NotFound.vue"

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home Page - Quad City Freight Service, Inc',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About Us - Quad City Freight Service, Inc',
    }
  },
  {
    path: '/employment',
    name: 'employment',
    component: Employment,
    props: {source: "qcfreightservice.com", sourceName: "Quad City Freight Service, Inc"},
    meta: {
      title: 'Employment - Quad City Freight Service, Inc',
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Contact Us - Quad City Freight Service, Inc',
    }
  },
  {
    path: '/track',
    name: 'track',
    component: Track,
    meta: {
      title: 'Quick Track - Quad City Freight Service, Inc',
    }
  },
  {
    path: '/loadtender',
    name: 'loadtender',
    component: LoadTender,
    meta: {
      title: 'Load Tenders - Quad City Freight Service, Inc',
    }
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'Not Found - Quad City Freight Service, Inc',
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
})

export default router
