import moment from "moment";
import jstz from "jstz";
import "moment-timezone";

export default {
    tzname: jstz.determine().name(),
    getLocalDateTime: function (date, format) {
        return moment
            .utc(date)
            .tz(this.tzname)
            .format(format);
    },
    getUTCDateTime: function (localdate, format) {
        return moment(localdate)
            .utc()
            .format(format);
    },
    getLocalDateTimeNow: function (format) {
        return moment
            .tz(this.tzname)
            .format(format);
    },
    localNow: function () {
        return moment().tz(this.tzname);
    },
    localNowFormatted: function (format) {
        return moment().tz(this.tzname).format(format);
    },
    utcNow: function () {
        return moment().utc();
    },
}
