<template>
  <b-container>
    <b-row>
      <b-col>
        <h2>Quick Track.</h2>
        <small><i>(* This system will only search for items in the last 9 months, if what you are looking for is older than that please contact <a href="mailto:customerservice@qcfreightservice.com">customer service</a>)</i></small>
        <b-card title="What can we find for you?">
          <b-form ref="searchForm" @submit="onSubmit">
            <b-form-group label-cols-md="1" label="Order:" label-for="form.order">
              <b-input id="form.order" trim v-model="form.order"/>
            </b-form-group>
            - OR -
            <b-form-group label-cols-md="1" label="Barcode:" label-for="form.barcode">
              <b-input id="form.barcode" trim v-model="form.barcode"/>
            </b-form-group>
            <b-button :disabled="!validSearch" type="submit" variant="primary">Search</b-button>
          </b-form>        
        </b-card>
        <h3 v-if="searched && results.length == 0">Sorry, we didn't find anything :(</h3>
        <b-card v-if="results.length > 0" :title="resultsTitle">
          <div v-for="(r, index) in results" :key="index">
            <div>
              <b>{{r.line.quantityShipped + r.line.overShort}} pieces</b>
              <template v-if="r.line.overShort > 0">(we reported an <strong>overage</strong> of <strong>{{r.line.overShort}}</strong> pieces)</template>
              <template v-if="r.line.overShort < 0">(we reported an <strong>shortage</strong> of <strong>{{-r.line.overShort}}</strong> pieces)</template>
              of order <b>{{resultOrder.toUpperCase()}}</b>:
            </div>
            <div>
              - 
              <span v-if="r.shipment">the <b>{{r.line.facility}}</b> facility <b>was notified</b> by <b>{{r.supplier.name}}</b> of the order on <b>{{ localD(r.shipment.dateShipped) }}</b> at <b>{{ localT(r.shipment.dateShipped)}}</b></span>
              <span v-if="!r.shipment"><b>pick from inventory</b> was recorded at facility <b>{{r.line.facility}}</b> on <b>{{ localD(r.line.dateCreated) }}</b> at <b>{{ localT(r.line.dateCreated)}}</b></span>
            </div>
            <div v-if="r.shipment && r.shipment.dateArrived">- the <b>{{r.shipment.facility}}</b> facility <b>finished receiving</b> the order on <b>{{ localD(r.shipment.dateArrived) }}</b> at <b>{{ localT(r.shipment.dateArrived)}}</b></div>
            <div v-if="r.barcodes && r.barcodes.length > 0">- the order was assigned <b>barcodes</b>: <b>{{arrayToCSV(r.barcodes, (b)=> b.externalId)}}</b></div>
            <template v-if="r.manifest != null">
              <div>- the order was assigned to <span v-if="r.bol">QCFS <b>BOL# {{r.bol.billOfLadingNo}}</b>, </span>QCFS <b>Manifest# {{r.manifest.manifestNo}}</b></div>
              <div>- the order was <b>loaded</b> onto <b>{{r.manifest.carrier.name}}({{r.manifest.carrier.id}})</b> trailer <b>{{r.manifest.trailerNo}}</b> 
                on ~ <b>{{ localD(r.line.dateMerged ? r.line.dateMerged : r.line.dateCreated) }}</b> at <b>{{ localT(r.line.dateMerged ? r.line.dateMerged : r.line.dateCreated)}}</b>
              </div>
            </template>
            <template v-if="r.bol != null && r.bol.finalized">
              <div>- the order was <b>ready for pickup</b> on <b>{{localD(r.bol.finalizedDate)}}</b> at <b>{{localT(r.bol.finalizedDate)}}</b></div>
              <div v-if="r.trailerStay && r.trailerStay.endStay">
                - the order left the <b>{{r.trailerStay.facility}}</b> facility on <b>{{localD(r.trailerStay.endStay)}}</b> at <b>{{localT(r.trailerStay.endStay)}}</b> enroute to
                <b>{{r.stop.dest.name}}</b> in <b>{{r.stop.dest.city}}, {{r.stop.dest.state}}</b>
              </div>
            </template>
            <hr/>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <LoadingSplash :loading="busy"/>
  </b-container>
</template>

<script>
import axios from "axios";
import dateutil from "../dateutil";
import LoadingSplash from "./LoadingSplash.vue";
import {MOVEMENT_API_URL,INDEX_API_URL,MANIFEST_API_URL} from "../qcfsconfig";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      form: {
        order: null,
        barcode: null,
      },
      busy: false,
      searched: false,
      results: [],
      resultOrder: null,
    }
  },
  computed: {
    validSearch() {
      return (this.form.order != null && this.form.order.length > 0) || (this.form.barcode != null && this.form.barcode.length > 0);
    },
    resultsTitle() {
      let t = "";
      switch (this.results.length) {
        case 0:
          t = "nothing";
          break;
        case 1:
          t = "We found it!";
          break;
        case 2:
          t = "We found a couple!";
          break;
        case 3:
          t = "We found a few!";
          break;
        default:
          t = "We found several!";
      }
      return t;
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      if(this.form.order != null && this.form.order.length > 0) {
        this.searchByOrder();
      }
      if(this.form.barcode != null && this.form.barcode.length >0) {
        this.searchByBarcode();
      }
    },
    arrayToCSV(arr, mapf) {
      if(mapf) {
        arr = arr.map(mapf);
      }
      return arr.join(", ");
    },
    localDT(datetime) {
      return dateutil.getLocalDateTime(datetime, "YYYY-MM-DD hh:mm a z");
    },
    localD(datetime) {
      if (datetime) {
        return dateutil.getLocalDateTime(datetime, "YYYY-MM-DD");
      } else {
        return "UNKNOWN";
      }
      
    },
    localT(datetime) {
      if (datetime) {
        return dateutil.getLocalDateTime(datetime, "h:mma z");
      } else {
        return "UNKNOWN";
      }
    },
    searchByOrder() {
      this.busy = true;
      this.searchIds()
      .then(ids => {
        return this.fetchResultsFromIds(ids);
      })
      .then(resObjs => {
        resObjs = resObjs.sort((a,b) => -a.line.dateCreated.localeCompare(b.line.dateCreated));
        this.results = resObjs;
        this.searched = true;
        this.resultOrder = this.form.order;
        this.resetForm();
      })
      .catch(err => {
        console.log(err);
        alert("There was a problem submitting your search, please try submitting again.");
      })
      .finally(() => {
        this.busy = false;
      });
    },
    searchByBarcode() {
      this.busy = true;
      this.searchBarcodes()
      .then(ids => {
        return this.fetchResultsFromIds(ids);
      })
      .then(resObjs => {
        resObjs = resObjs.sort((a,b) => -a.line.dateCreated.localeCompare(b.line.dateCreated));
        this.results = resObjs;
        this.searched = true;
        this.resetForm();
      })
      .catch(err => {
        console.log(err);
        alert("There was a problem submitting your search, please try submitting again.");
      })
      .finally(() => {
        this.busy = false;
      });
    },
    async searchIds() {
      let payload = { query: { term: { line_orders: { value: this.form.order.toLowerCase() } } }, size: 50 };
      let url = `${INDEX_API_URL}/search`;
      return axios.post(url, payload, { headers: { "Content-Type": "application/json" }})
      .then(res => {
        return res.data.hits.hits.map(a => a._source.line_id);
      });
    },
    async searchBarcodes() {
      let url = `${MANIFEST_API_URL}/parcel?barcode=${this.form.barcode}`;
      return axios.get(url)
      .then(res => {
        return res.data.payload.map(a => a.incomingShipmentId);
      });
    },
    async fetchResultsFromIds(ids) {
      let rslts = await this.loadLinesFromIds(ids);
      let lines = rslts.filter(r => r.status == "fulfilled").map(r => r.value);
      let x = lines.map(line => {
        this.resultOrder = line.orderNo;
        let lineProms = [];
        let result = {
          line: line,
          shipment: null,
          manifest: null,
          barcodes: null,
          supplier: null,
          stop: null,
          bol: null,
          trailerStay: null,
        };
        if(line.incomingShipmentHeaderId != null) {
          lineProms.push(this.loadShipment(result).then(r => result.shipment = r.data));
        }
        if(line.manifestNo != null) {
          lineProms.push(this.loadManifest(result).then(r => {
            result.manifest = r.data;
            return this.loadParty(result.manifest.carrierCode).then(r => {
              result.manifest.carrier = r.data;
              if(result?.manifest?.stopId) {
                return this.loadStop(result).then(r => {
                  result.stop = r.data;
                  return this.loadParty(result.stop.stopCode).then(r => {
                    result.stop.dest = r.data;
                    return this.loadBol(result.stop.billOfLadingNo).then(r => {
                      result.bol = r.data;
                      if(result.bol.finalized && result.bol.trailerStayId) {
                        return this.loadTrailerStay(result.bol.trailerStayId).then(r => {
                          if(r.data && r.data.payload) {
                            result.trailerStay = r.data.payload;
                            return r.data.payload;
                          } else {
                            return;
                          }
                        });
                      } else {
                        return result.bol;
                      }
                    });
                  });
                })
              } else {
                return r.data.payload;
              }
            });
          }));
        }
        lineProms.push(this.loadParcels(result).then(r => result.barcodes = r.data.payload));
        lineProms.push(this.loadParty(result.line.billToCode).then(r => result.supplier = r.data));

        return Promise.all(lineProms).then(() => result);
      });
      return Promise.all(x);
    },
    async loadStop(result) {
      let url = `${MANIFEST_API_URL}/stop/${result.manifest.stopId}`;
      return axios.get(url);
    },
    async loadShipment(result) {
      let url = `${MANIFEST_API_URL}/shipment/${result.line.incomingShipmentHeaderId}`;
      return axios.get(url);
    },
    async loadManifest(result) {
      let url = `${MANIFEST_API_URL}/manifest/${result.line.manifestNo}`;
      return axios.get(url);
    },
    async loadBol(bolNo) {
      let url = `${MANIFEST_API_URL}/bol/${bolNo}`;
      return axios.get(url);
    },
    async loadParcels(result) {
      let url = `${MANIFEST_API_URL}/line/${result.line.id}/parcels`;
      return axios.get(url);
    },
    async loadParty(party) {
      let url = `${MANIFEST_API_URL}/party?id=${party}`;
      return axios.get(url);
    },
    async loadTrailerStay(trailerStayId) {
      let url = `${MOVEMENT_API_URL}/trailerstays/${trailerStayId}`;
      return axios.get(url);
    },
    loadLinesFromIds(ids) {
      let proms = [];
      ids.forEach(id => {
        let url = `${MANIFEST_API_URL}/line/${id}`;
        let c = axios.get(url).then(r => r.data.payload);
        proms.push(c);
      });
      return Promise.allSettled(proms);
    },
    resetForm() {
      this.form.order = null;
      this.form.barcode = null;
    }
  }
}
</script>