<template>
  <b-container>
    <b-row>
      <b-col>
      <h2>Contact Us.</h2>
      <b-form ref="contactForm" @submit="onSubmit">
        <b-form-group label-cols-md="1" label="Name:" label-for="form.name">
          <b-input id="form.name" trim required v-model="form.name"/>
        </b-form-group>
        <b-form-group label-cols-md="1" label="Email:" label-for="form.email">
          <b-input id="form.email" trim required v-model="form.email"/>
        </b-form-group>
        <b-form-group label-cols-md="1" label="Phone:" label-for="form.phone">
          <b-input id="form.phone" trim required v-model="form.phone"/>
        </b-form-group>
        <b-form-group label-cols-md="1" label="Inquiry:" label-for="form.inquiry">
          <b-textarea cols="5" id="form.inquiry" trim required v-model="form.inquiry"/>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
      <hr/>
      <p>
        Phone: 563-355-9245<br/>
        Fax: 563-355-9251<br/>
        Email: info@qcfreightservice.com<br/>
        <br/>
        Quad City Freight Service, Inc.<br/>
        PO Box 1480<br/>
        Bettendorf, IA 52722
      </p>        
      </b-col>
    </b-row>
    <LoadingSplash :loading="busy"/>
  </b-container>
</template>

<script>
import axios from "axios";
import LoadingSplash from "./LoadingSplash.vue";
import {MAILER_URL} from "../qcfsconfig";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        email: null,
        inquiry: null,
      },
      busy: false,
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.busy = true;
      let payload = {
        senderInfo: {
          user:"qcfssystems@qcfreightservice.com",
          pass:"qcf5$yst3ms",
          from:"'QCFS Systems' <qcfssystems@qcfreightservice.com>",
          to:"info@qcfreightservice.com,jsmith@qcfreightservice.com",
          subject:`Inquiry Submitted by ${this.form.name}`
        },
        data: JSON.parse(JSON.stringify(this.$data.form)),
        template: "contactUs.vue"
    };
    payload.data.source = "qcfreightservice.com";
    payload.data.sourceName = "Quad City Freight Service, Inc";
    axios.post(MAILER_URL, payload, { headers: { "Content-Type": "application/json" }})
      .then(() => {
        this.$refs.contactForm.reset();
        this.busy = false;
        alert("Your inquiry was submitted successfully, thank you!");
        this.$router.push("/");
      })
      .catch(() => alert("There was a problem submitting your inquiry, please try submitting again."))
      .finally(() => this.busy = false);
    }
  }
}
</script>