<template>
    <b-container>
        <h3 :class="resultClass">{{ result }}</h3>
        <LoadingSplash :loading="busy"/>
    </b-container>
</template>

<script>
import axios from "axios";
import LoadingSplash from "./LoadingSplash.vue";
import {MISC_API_URL} from "../qcfsconfig";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      action: this.$route.query != null ? this.$route.query.a : null,
      transFile: this.$route.query != null ? this.$route.query.t : null,
      result: "",
      resultClass: "",
      busy: false,
    }
  }, 
  computed: {
    transaction() {
      return this.transFile.substring(0,this.transFile.lastIndexOf("."));
    }
  },  
  mounted() {
      let url = `${MISC_API_URL}/api/loadtender/${this.action}?t=${this.transFile}`;
      this.busy = true;
      this.result = `Processing transaction ${this.transaction}...`;
      axios.get(url)
      .then(() => {
        this.resultClass = "ok";
        this.result = `Transaction ${this.transaction} ${this.action}ed!`;
      })
      .catch(err => {
        this.resultClass = "error";
        if (err.response) {
          if(err.response.status == 404) {
            this.result = `Cound not find transaction ${this.transaction}.`;
          } else if(err.response.status == 301) {
            this.result = `Transaction ${this.transaction} already processed`;
          } else {
            this.result = err;
          }
        } else {
          this.result = err;
        }
      })
      .finally(() => this.busy = false);
  }
}
</script>
<style scoped>
.ok {
  color: green;
}
.error {
  color: red;
}
</style>