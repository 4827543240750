<template>
  <b-container id="app" fluid="lg">
    <b-row>
      <b-col style="padding:0px"><img src="./assets/qcfs-logo.jpg"/></b-col>
    </b-row>
    <b-row>
      <b-col style="padding: 0px 0px">
        <b-navbar toggleable="md">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'home' }" exact exact-active-class="active">Home</b-nav-item>
              <b-nav-item :to="{ name: 'about' }" exact exact-active-class="active">About Us</b-nav-item>
              <b-nav-item href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=6a2aa878-4d2f-4563-9a6a-420e73bb0400&ccId=19000101_000001&lang=en_US">Employment</b-nav-item>
              <b-nav-item :to="{ name: 'contact' }" exact exact-active-class="active">Contact Us</b-nav-item>
              <b-nav-item :to="{ name: 'track' }" exact exact-active-class="active">Quick Track</b-nav-item>
              <b-nav-item href="https://qcfs.simplybook.me/">Book Live Unload</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
    <router-view></router-view>
    <b-row class="card-footer footer small font-weight-lighter">
      <b-col>
        <div>
          &copy;2014-{{currentYear}} Quad City Freight Service, Inc {{appVersion}}
          <div class="float-right font-italic">
            Served from {{cluster}} {{clusterNode}} at {{servedAt}}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jstz from "jstz";
import moment from 'moment';
import "moment-timezone";
import {QCFS_CLUSTER,QCFS_CLUSTER_NODE,QCFS_VERSION} from "./qcfsconfig";

const tzname = jstz.determine().name();

export default {
  data() {
    return {
      currentYear: moment.tz(tzname).format("YYYY"),
      appVersion: QCFS_VERSION,
      cluster: QCFS_CLUSTER,
      clusterNode: QCFS_CLUSTER_NODE,
      servedAt: moment.tz(tzname).format("YYYY-MM-DD hh:mma z"),
    }
  }
}
</script>

<style scoped>
.active {
  background-color: red;
  border-radius: 10px;
}
.navbar-light .navbar-nav .nav-link.active {
  color: white;
}
.footer {
  background-color: red;
  color: white;
}
</style>
